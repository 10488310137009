<template>
  <b-card>
    <!-- PLACES TABLE -->
    <b-static-table
      :items="mappedPlaces"
      :fields="placesTableFields"
      :loading="loading"
      :button-text="$t('Nou lloc')"
      show-button
      show-search
      show-actions
      @button-click="onNewPlaceClicked"
      @item-edit-click="onEditPlaceClicked"
      @item-delete-click="onDeletePlaceClicked"
    />

    <!-- ADD PLACE MODAL -->
    <b-modal
      :id="'add-place-modal'"
      title="Afegeix un nou lloc"
      size="lg"
      scrollable
      hide-footer
    >
      <place-form
        @cancel="onAddPlaceCancel"
        @place-added="onPlaceAdded"
        @add-place-error="onAddPlaceError"
      />
    </b-modal>

    <!-- EDIT PLACE MODAL -->
    <b-modal
      :id="'edit-place-modal'"
      title="Edita el lloc"
      size="lg"
      scrollable
      hide-footer
    >
      <place-form
        :place="selectedPlace"
        @cancel="onEditPlaceCancel"
        @place-edited="onPlaceEdited"
        @edit-place-error="onEditPlaceError"
      />
    </b-modal>
  </b-card>
</template>

<script>
import PlaceForm from "@/views/places/list/components/PlaceForm.vue";
import BStaticTable from "@foravila-core/components/b-static-table/BStaticTable.vue";
import { getPlaceTypeName, notifyError, notifySuccess } from "@/utils/methods";
import { BCard, BModal } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BModal,
    PlaceForm,
    BStaticTable,
  },
  data() {
    return {
      selectedPlace: null,
      placesTableFields: [
        { key: "name", label: this.$t("Lloc"), sortable: true },
        { key: "type", label: this.$t("Tipus"), sortable: true },
        { key: "location", label: this.$t("Ubicació"), sortable: true },
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["places/loading"];
    },
    places() {
      return this.$store.getters["places/places"];
    },
    mappedPlaces() {
      return this.places.map((place) => ({
        uuid: place.uuid || this.$t("No definit"),
        name: place.name || this.$t("No definit"),
        location: place.location?.city || this.$t("No definit"),
        type: getPlaceTypeName(place.type) || this.$t("No definit"),
      }));
    },
  },
  created() {
    if (this.places.length === 0 && !this.loading) this.fetchPlaces();
  },
  methods: {
    fetchPlaces() {
      this.$store
        .dispatch("places/fetchPlaces", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPlaces.title"),
            this.$t("errors.fetchPlaces.description")
          )
        );
    },
    onNewPlaceClicked() {
      this.$bvModal.show("add-place-modal");
    },
    onEditPlaceClicked(placeUuid) {
      this.selectedPlace = this.places.find(
        (place) => place.uuid === placeUuid
      );
      this.$bvModal.show("edit-place-modal");
    },
    onDeletePlaceClicked(placeUuid) {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("places/deletePlace", placeUuid)
        .then(() => {
          this.fetchPlaces();
          notifySuccess(
            "Lloc eliminat",
            "El lloc ha estat eliminat correctament"
          );
        })
        .catch(() => {
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar eliminar el lloc"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onAddPlaceCancel() {
      this.$bvModal.hide("add-place-modal");
    },
    onPlaceAdded() {
      this.fetchPlaces();
      this.$bvModal.hide("add-place-modal");
      notifySuccess("Lloc afegit", "El lloc ha estat afegit correctament");
    },
    onAddPlaceError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir el lloc");
    },
    onEditPlaceCancel() {
      this.$bvModal.hide("edit-place-modal");
    },
    onPlaceEdited() {
      this.fetchPlaces();
      this.$bvModal.hide("edit-place-modal");
      notifySuccess("Lloc editat", "El lloc ha estat editat correctament");
    },
    onEditPlaceError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar el lloc");
    },
  },
};
</script>
