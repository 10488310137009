<template>
  <div id="place-form">
    <!-- NAME -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Nom</h6>
      </b-col>
      <b-col cols="12">
        <b-form-input v-model="name" autocomplete="off" />
      </b-col>
    </b-row>

    <!-- TYPE -->
    <b-row class="mb-3">
      <b-col cols="12" class="mb-1">
        <h6>Tipus</h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="type"
          :options="placeTypeOptions"
          :reduce="(option) => option.value"
        />
      </b-col>
    </b-row>

    <!-- LOCATION -->
    <b-location v-model="location" hide-icons class="mb-3" />

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BFormInput } from "bootstrap-vue";
import BLocation from "@foravila-core/components/b-location/BLocation.vue";
import { placeTypeOptions } from "@/utils/select-options";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    vSelect,
    BLocation,
  },
  props: {
    place: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      name: null,
      type: null,
      location: null,
      placeTypeOptions,
    };
  },
  created() {
    if (this.place) this.initForm();
  },
  methods: {
    initForm() {
      if (!this.place) return;
      this.name = this.place.name;
      this.type = this.place.type;
      this.location = this.place.location;
    },
    resetForm() {
      this.name = null;
      this.type = null;
      this.location = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      if (this.place) this.updatePlace();
      else this.createPlace();
    },
    createPlace() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("places/addPlace", {
          name: this.name,
          type: this.type,
          location: this.location,
        })
        .then(() => this.$emit("place-added"))
        .catch(() => this.$emit("add-place-error"))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    updatePlace() {
      this.$store.dispatch("app/setLoading", true);

      const promises = [];

      if (this.place.location) {
        // If the place that we are editing already has a location
        // Update the location
        promises.push(
          this.$store.dispatch("places/updatePlaceLocation", {
            placeUuid: this.place.uuid,
            location: {
              ...this.location,
              uuid: this.place.location.uuid,
            },
          })
        );
        // Update the place (without location)
        promises.push(
          this.$store.dispatch("places/updatePlace", {
            uuid: this.place.uuid,
            name: this.name,
            type: this.type,
          })
        );
      } else {
        // If the place that we are editing doesn't have a location
        // Update the place (creating the location on the fly)
        promises.push(
          this.$store.dispatch("places/updatePlace", {
            uuid: this.place.uuid,
            name: this.name,
            type: this.type,
            location: this.location,
          })
        );
      }

      Promise.all(promises)
        .then(() => this.$emit("place-edited"))
        .catch(() => this.$emit("edit-place-error"))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#place-form {
  .map-container {
    height: 400px;
    width: 100%;
  }
}
</style>
